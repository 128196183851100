@font-face {
    font-family: nunito;
    src: url(../fonts/Nunito.ttf);
}

:root{
    --mainColor:#4F4F51;
    --secondaryColor:#2C2B30;
    --contrastColor:#F58F7C;
    --shadowColor:#D6D6D6;
    --accentColor:#F2C4CE;
}

a{
    text-decoration:none;
    color:var(--accentColor);
}


body{
    height:100vh;
    width:100vw;
    display:flex;
    flex:none;
    margin:0px;
    padding:none;
    font-family:nunito, sans-serif;
}

#page{
    display:grid;
    grid-template-columns: 1fr 6fr;
    padding:none;
    margin:none;
    height:100%;
    width:100%;
}

#sidebar{
    width: 14%;
    justify-content:flex-start;
    display:grid;
    grid-template-rows: 1fr 3fr 3fr;
    background-color:var(--secondaryColor);
    height:100%;
    font-size:larger;
    padding-left:2%;
    font-weight:bold;
    gap:2%;
    box-shadow: 2px 0px 5px black;
    z-index: 10;
    position:fixed;
    text-align:left;
    margin:none;
}

#main{
    grid-column:2/3;
    display:grid;
    grid-template-rows: 1fr 3fr 3fr;
    background-color:var(--mainColor);
    gap:5%;
    padding:5% 15% 10% 8%;
    color:var(--accentColor);
}

.logo{ 
    display:flex;
    align-self:flex-start;
    justify-self:flex-start;
    margin-top:5px;
    color:var(--contrastColor);
    font-size: 1.6em;
    font-weight:bolder;
    font-style:italic;
    /* padding: 5% 0 0 5%; */
    position:static;
}


.portfolio{
    display:grid;
    grid-template-rows: 1fr 1fr 1fr;
    color: var(--accentColor);
    border-top:1px solid var(--accentColor);
    /* overflow:hidden; */
    width:120px;
    /* width:98%; */
    /* width:90%+60px; */
}

#links{
    display:flex;
    flex-direction:column;
    flex:none;
    justify-items:space-between;
    color:var(--accentColor);
    gap:5px;
}

#title{
    display:flex;
    align-self:flex-start;
    justify-self:flex-start;
    color:var(--accentColor);
    font-size:2.4em;
    padding: 20px 0 0 30px;
    font-style:italic;
    /* font-weight:bold; */
    border-bottom:1px solid var(--accentColor);
}

#topCard{
    display:grid;
    grid-template-columns: 3fr 8fr;
    color:var(--accentColor);
    gap:2%;
    align-items:center;
}

#bottomCard{
    display:grid;
    grid-template-columns: 3fr 2fr;
    color:var(--accentColor);
    gap:5%;
    align-items:center;
}

.cardText{
    padding-top:25px;
}

.cardImage{
    display:flex;
    align-items:center;
    justify-content:center;
}

.link:hover{
    color:var(--contrastColor);
    cursor:pointer;
}

.linkButton{
    box-shadow:none;
}

#linkedIn{
    background-image:"../images/linkedin-rose.svg";
    background-color:blue;
}

.linkButton:hover{
    background-color:var(--contrastColor);
    border-radius:50%;
}

.feather {
    width: 48px;
    height: 48px;
    stroke: var(--accentColor);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

img{
    height:85%;
    width:85%;
    border-radius:3px;
    box-shadow:2px 2px 5px black;
    border:1px solid var(--secondaryColor);
}

.iconLink{
    display:flex;
    background-color:var(--secondaryColor);
    height:40px;
    width:40px;
    align-self:center;
    justify-self:flex-start;
}


.iconLink:hover{
    background-color:var(--contrastColor);
    transition-property:padding, background-color;
    transition-duration: .4s, .4s;
    padding:10px;
    border-radius:50%;
    cursor:pointer;
}

.iconLink:not(:hover){
    background-color:var(--secondaryColor);
    transition-property:padding, background-color, border-radius;
    transition-delay:0s, 0s, .5s;
    transition-duration: .5s, .5s, 0s;
    padding:0px;
    border-radius:0%;
    color:var(--accentColor);
}

.linkText{
    font-weight:normal;
}

.linkText:hover{
    cursor:pointer;
    color: var(--contrastColor); 
    font-weight:bold;
}

.iconLink:hover + .linkText {
    color: var(--contrastColor); 
    font-weight:bold;
}

.linkRow{
    display:flex;
    flex-direction:row;
    align-items:center;
    /* gap:5px; */
    font-size:smaller;
    font-style:italic;
}

.linkRow:hover > a > .linkText {
    color: var(--contrastColor); 
    font-weight:bold;
}

.linkRow:hover > a > .iconLink, .linkRow:hover > .iconLink {
    background-color:var(--contrastColor);
    transition-property:padding, background-color;
    transition-duration: .4s, .4s;
    padding:10px;
    border-radius:50%;
    cursor:pointer;
}

.linkRow:not(:hover) > a > .iconLink, .linkRow:not(:hover) > .iconLink{
    background-color:var(--secondaryColor);
    transition-property:padding, background-color, border-radius;
    transition-delay:0s, 0s, .5s;
    transition-duration: .5s, .5s, 0s;
    padding:0px;
    border-radius:0%;
    color:var(--accentColor);
}

.skillsTitle{
    display:flex;
    font-weight:bold;
    font-size:1.2em;
    justify-content:center;
    align-content:center;
}
.skillsBox{
    padding-top:5%;
    background-color: var(--shadowColor);
    color:var(--secondaryColor);
    border-radius:5%;
    /* border:2px solid var(--secondaryColor); */
    gap:0.25em;
    box-shadow:2px 2px 5px black;
    border:1px solid var(--accentColor);
}

.skillBoxes{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10%;
    border-top: 3px double var(--accentColor);
    padding:10%;
    padding-bottom:0;
    margin-bottom:10%;
    background-color:var(--mainColor);
}